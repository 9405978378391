import React from "react";
import "./LandSection.css";

const LandSection = () => {

    const openPdf = () => {
        sessionStorage.setItem("isLanding", false);
        const pdfUrl = process.env.PUBLIC_URL + "/assets/Yapa_Menu.pdf";
        window.open(pdfUrl, "_self");
      };

      const handleClick = () => {
        sessionStorage.setItem("isLanding", false);
        const url = "https://maps.app.goo.gl/TByzi9ETeqcvsqX29";
        window.open(url, "_self");
      };
      const VisitWebsite= () => {
        sessionStorage.setItem("isLanding", false);
        const url = "https://yaparestaurant.com/";
        window.open(url, "_self");
      };
  return (
    <section className="section_home-hero">
      <div className="padding-global">
        <div className="container-large">
          <div className="padding-section-large">
            <div className="home-hero_component">
              {/* Logo centered in the hero component */}
              <img
                className="image-9"
                src="https://yaparestaurant.com/assets/images/Yapa-Logo-White.png"
                width="447"
                alt="Husk Micro Diner Logo White"
                loading="lazy"
              />
              <div class="padding-bottom padding-small">     <h2 style={{ 
                marginBottom: '50px',
                color: 'white',
                textAlign: 'center',
                lineHeight: '1.5'
            }}>Elevate your taste at YAPA</h2></div>
              {/* Button group centered under the logo */}
              <div className="button-group is-center">
                <button className="button w-button" onClick={openPdf}>Menu</button>
                <button className="button w-button" onClick={handleClick}>Location</button>
                <button className="button w-button" onClick={VisitWebsite} >Visit Website</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandSection;
