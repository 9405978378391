import React, { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import MobileMenu from "../components/MobileMenu";
import Footer from "./Footer";
import { ScrollProvider } from "./ScrollContext";

const Layout = ({ children }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth > 768);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setWindowSize(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function scrollToSection(className) {
    const elements = document.getElementsByClassName(className);

    if (elements.length > 0) {
        const targetElement = elements[0];
        const targetOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const initialOffset = window.pageYOffset;
        const distance = targetOffset - initialOffset;

        const duration = 800; // Extended duration of the scroll animation in milliseconds

        let startTime;
        function scrollAnimation(currentTime) {
            if (!startTime) startTime = currentTime;
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1); // Linear progress
            const easedProgress = easeInOutQuad(progress); // Apply easing function

            window.scrollTo(0, initialOffset + distance * easedProgress);

            if (progress < 1) {
                requestAnimationFrame(scrollAnimation);
            }
        }

        requestAnimationFrame(scrollAnimation);
        hideMobileMenu(); // Ensure hideMobileMenu is defined
    } else {
        console.warn(`No elements found with the class name: ${className}`);
    }
}

// Easing function for smoother scrolling (quadratic easing in/out)
function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
}



  function hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
  }

  return (
    <Fragment>
      <ScrollProvider scrollToSection={scrollToSection}>
        <div className="page-wrapper">
          <Header windowSize={windowSize} />
          {children}
          <Footer />
        </div>
        <div className="mobile-menu-overlay" onClick={hideMobileMenu}></div>
        <MobileMenu />
      </ScrollProvider>
    </Fragment>
  );
};

export default Layout;
