// HeroSlider.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

// const slideStyle = {
//   backgroundColor: 'transparent',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover',
//   backgroundPosition: 'center center',
//   width: '100%',
//   height: '100%',
//   opacity: 1,
//   transform: 'translate(0px, 0px)',
//   visibility: 'inherit',
// };

const slideStyle = {
  position: "relative", // Necessary for the absolute positioning of slide-text
  display: "flex",
  justifyContent: "center", // Horizontal centering
  alignItems: "center", // Vertical centering
  backgroundColor: "transparent",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  width: "100%",
  height: "100%",
  paddingTop: "100px",
};

const HeroSlider = () => {
  return (
    <div className="home">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        <SwiperSlide
          style={{
            ...slideStyle,
            backgroundImage: "url(/assets/upload/Main-Banner.png)",
          }}
        >
          <div className="slide-text">
            <p>We Use The Best Ingredients To Carefully</p>
            <p>Create The Best American-English Dishes For You.</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSlider;
