import React from "react";
import { useScrollContext } from "../Layouts/ScrollContext";
import $ from "jquery"; // Import jQuery

const MobileMenu = () => {
  const closeMenu = () => {
    document.body.classList.remove("js_nav");
  };
  const scrollToSection = useScrollContext();

  const handleMenuClick = (sectionId) => {
    scrollToSection(sectionId);
    toggleMobileMenu(); // Close mobile menu after clicking a menu item
  };

  const toggleMobileMenu = () => {
    $("body").toggleClass("js_nav");
  };

  return (
    <div className="mobile_menu_wrapper">
      <a id="close_mobile_menu" onClick={() => closeMenu()}>
        <i className="fa fa-times-circle"></i>
      </a>
      <div className="menu-main-menu-container">
        <ul id="mobile_main_menu" className="mobile_main_nav">
          <li className="menu-item">
            <a href="#home" onClick={() => handleMenuClick("home")}>
              Home
            </a>
          </li>
          <li className="menu-item">
            <a href="#about-us" onClick={() => handleMenuClick("about-us")}>
              About Us
            </a>
          </li>
          <li className="menu-item">
            <a href="#menu" onClick={() => handleMenuClick("menu")}>
              Menu
            </a>
          </li>
          <li className="menu-item">
            <a
              href="#our-location"
              onClick={() => handleMenuClick("our-location")}
            >
              Our Location
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
