import React ,{useState,useEffect} from "react";
import $ from "jquery"; // Import jQuery
import { useScrollContext } from "./ScrollContext";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Toggle the js_nav class
    setIsNavOpen(prev => !prev);
  };

  // Use useEffect to add/remove the class to the body when isNavOpen changes
  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.add('js_nav');
    } else {
      document.body.classList.remove('js_nav');
    }

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('js_nav');
    };
  }, [isNavOpen]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down a certain amount
      const scrollPosition = window.scrollY;
      const threshold = 100; // Adjust this threshold as needed

      setIsFixed(scrollPosition > threshold);
    };

    // Add event listener for the scroll event
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToSection = useScrollContext();

  const handleMenuClick = (sectionId) => {
    scrollToSection(sectionId);
    toggleMobileMenu(); // Close mobile menu after clicking a menu item
  };

  const toggleMobileMenu = () => {
    document.body.classList.toggle('js_nav');
  };

  return (
    <div className="header_style_wrapper">
      <div className="top_bar hasbg">
        <div id="mobile_nav_icon" onClick={toggleMobileMenu}></div>

        <div id="menu_wrapper">
          <a id="custom_logo" className="logo_wrapper hidden" href="/">
            <img
              src={isFixed ? "/assets/images/Yapa_Logo-033.png" : "/assets/images/Yapa-Logo-White.png"}
              style={{marginTop:"15px"}}
              width="150"
              height="60"
            />
          </a>

          <a
            id="custom_logo_transparent"
            className="logo_wrapper default"
            href="/"
           
          >
            <img
              src={isFixed ? "/assets/images/Yapa_Logo-033.png" : "/assets/images/Yapa-Logo-White.png"}
              alt=""
              style={{marginTop:"15px"}}
              width="150"
              height="60"
            />
          </a>

          <div id="nav_wrapper">
            <div className="nav_wrapper_inner">
              <div id="menu_border_wrapper">
                <div className="menu-main-menu-container">
                  <ul id="main_menu" className="nav">
                    <li className="menu-item menu-item-home menu-item-has-children">
                      <a
                        id="home_link"
                        href="#home"
                        onClick={() => handleMenuClick("home")}
                        style={isFixed ? {color:"#32645B"} :{} }
                      >
                        Home
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="#about-us"
                        onClick={() => handleMenuClick("about-us")}
                        style={isFixed ? {color:"#32645B"} :{} }
                      >
                        About Us
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="#menu"
                        onClick={() => handleMenuClick("menu")}
                        style={isFixed ? {color:"#32645B"} :{} }
                      >
                        Menu
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="#our-location"
                        onClick={() => handleMenuClick("our-location")}
                        style={isFixed ? {color:"#32645B"} :{} }
                      >
                        Our Location
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
