import "./App.css";
import { Fragment, useEffect, useState } from "react";
import AppRoute from "./Routes/AppRoute";
import $ from "jquery";
import LandSection from "./LandSection";
import Loader from "./Loader"; // Import the Loader component

function App() {
  const [load, setLoad] = useState(true);
  const [isload, setIsLoad] = useState(true);
  const [isLanding, setIsLanding] = useState(false);

  useEffect(() => {
    // Initial check and set of isLanding
    if (window.innerWidth <= 768) {
      const isLandingStored = sessionStorage.getItem("isLanding");
      if (!isLandingStored) {
        sessionStorage.setItem("isLanding", true);
        setIsLanding(true);
      }
    } else {
      setIsLanding(false);
    }
    setIsLoad(false); // Loading completed
  }, []);

  useEffect(() => {
    if (isLanding) {
      document.body.style.backgroundColor = "#32645B";
    } else {
      document.body.style.backgroundColor = "white";
    }
  }, [isLanding]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fancyboxLock = $("#fancybox-lock");
    if (fancyboxLock.length) {
      fancyboxLock.remove();
    }
    setLoad(true);

    const scripts = [
      "/assets/js/plugins/fancybox/jquery.fancybox.js",
      "/assets/js/plugins/parallax.min.js",
      "/assets/js/plugins/combined.js",
    ];

    scripts.forEach((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    });
  }, [load]);

  console.log(sessionStorage.getItem("isLanding"))
  return (
    <Fragment>
      {isload ? (
        <Loader />
      ) : sessionStorage.getItem("isLanding") &&
        sessionStorage.getItem("isLanding") === "true" &&
        window.innerWidth <= 768 ? (
        <LandSection />
      ) : (
        <AppRoute />
      )}

      <div id="ajax_loading">
        <i className="fa fa-spinner fa-spin"></i>
      </div>
    </Fragment>
  );
}

export default App;
