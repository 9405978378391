import React from "react";

const Footer = () => {
  return (
    <div className="footer_bar">
      <div id="footer" className="">
        <ul className="sidebar_widget three">
          <li id="text-7" className="widget widget_text">
            <div className="textwidget">
              <img src="/assets/upload/Footer-Image.png" alt="" />
            </div>
          </li>
          <li id="text-8" className="widget widget_text">
            <h2 className="widgettitle">Address</h2>
            <div className="textwidget">
              <p>
                <i className="fa fa-map-marker marginright"></i>Beirut,  Achrafieh,  Alexander Street
              </p>
            </div>
          </li>
          <li id="text-9" className="widget widget_text">
            <h2 className="widgettitle">Open Hours</h2>
            <div className="textwidget">
              <p>
                <i className="fa fa-clock-o marginright"></i>Monday – Sunday
                <br />
                08:00 AM – 12:00 AM
                <br />
               <a href="tel:+96181801012"> <i className="fa fa-phone marginright"></i>+961 81 80 10 12
                </a>
              </p>
            </div>
          </li>
          <li id="text-10" className="widget widget_text">
            <h2 className="widgettitle">Find Us On</h2>
            <div className="textwidget">
              <div className="social_wrapper shortcode light">
                <ul>
                  <li className="facebook">
                    <a title="Instagram" href="https://www.instagram.com/yapa.lb?igsh=ZGd3d21wY2tiMG9l&utm_source=qr" rel="noopener noreferrer">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        <br className="clear" />
      </div>

      <div className="footer_bar_wrapper">
        <div className="menu-footer-menu-container">
          {/* <ul id="footer_menu" className="footer_nav">
            <li className="menu-item">
              <a href="our-chef/index.html">Our Chef</a>
            </li>
            <li className="menu-item">
              <a href="reservation/index.html">Reservation</a>
            </li>
            <li className="menu-item">
              <a href="shop/index.html">Shop</a>
            </li>
          </ul> */}
        </div>
        <div id="copyright">
          © Copyright  By{" "}
          <a href="/">Vision & more</a> All Right
          Reserved.
        </div>
        <br className="clear" />
        <div id="toTop">
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    </div>
  );
};

export default Footer;
