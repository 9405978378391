import React ,{useEffect} from "react";
import HeroSlider from "../components/HeroSlider";
import Layout from "../Layouts/Layout";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import './OurLocation.css'
const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [openFavorite,setOpenFavorite]=React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    // Update the z-index of .top_bar.fixed when openFavorite changes
    const topBarElements = document.getElementsByClassName('top_bar fixed');
    for (let element of topBarElements) {
      element.style.zIndex = openFavorite || open ? 1 : 99999999;
    }
  }, [openFavorite,open]);
  const handleClick = () => {
    setOpenFavorite(true); // Set the state
    updatePortfolioFilterWrapperZIndex(1); // Change the z-index
  };

  const handleCloseClick = () => {
    setOpenFavorite(false); // Set the state to false
    updatePortfolioFilterWrapperZIndex(9999); // Set z-index to 99999999
  };

  // Function to update the z-index of the '.portfolio_filter_wrapper' class
  const updatePortfolioFilterWrapperZIndex = (zIndexValue) => {
    const elements = document.getElementsByClassName('portfolio_filter_wrapper');
    for (let element of elements) {
      element.style.zIndex = zIndexValue;
    }
  };
  return (
    <Layout>
      <HeroSlider />
      <br className="clear" />

      <div className="ppb_wrapper">
        <div
          className="one withsmallpadding about-us"
          // style={{ padding: "30px 0 60px 0" }}
          id="about-us" // Add an ID to the section you want to navigate to
        >
          <h2 className="about-us-title">About US</h2>
          <div className="page_content_wrapper">
            <div className="one_half">
              <img src="/assets/upload/About-Us-Image.png" alt="" />
            </div>
            <div className="one_half last">
              {/* <h2>About Us</h2> */}
              <div
                className="page_caption_desc"
                style={{ marginTop: "10px", marginBottom: "20px" }}
              >
                Welcome to YAPA
              </div>
              Where American-English cuisine meets an exceptional dining
              experience. Whether you're joining us for brunch, lunch, or
              dinner, we promise a culinary journey that delights your taste
              buds and satisfies your cravings.
              <br />
              At YAPA, we take pride in serving the finest dishes, crafted with
              the freshest ingredients and a touch of culinary artistry. From
              our delectable brunch options to our sumptuous lunch and dinner
              offerings, every meal is a testament to our commitment to quality
              and flavor.
              <br />
              But that’s not all — our milkshakes are the talk of the town!
              Indulge in the best milkshakes that perfectly complement our menu,
              making every visit to YAPA a delightful treat.
              <br />
              We invite you to elevate your taste buds and experience the best
              in fine dining at YAPA. Come and discover a place where great food
              and a welcoming atmosphere come together to create unforgettable
              moments.
            </div>
            <p></p>
          </div>
        </div>
        <div
          id="plates"
          className="ppb_menu_classic one withpadding"
          style={{ padding: "30px 0px 0" }}
        >
          <div className="page_content_wrapper">
            <h2 className="about-us-title">Yapa's Favorites</h2>
            <div className="portfolio_filter_wrapper gallery three_cols portfolio-content section content clearfix">
              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated1 static">
                  <div
                    data-title="<strong>Spicy Crab Ramen</strong>"
                    href="/assets/upload/Pasta-Ebi-Tempura-To-Gomatare.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/1.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2904"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">DYNAMITE SHRIMPS</span>
                    <span className="menu_price">$12.5</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    7 Shrimp Pieces served with the Sriracha mayo sauce
                  </div>
                </div>
              </div>
              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated2 static">
                  <div
                    data-title="<strong>Fresh Crab With Lemon</strong>"
                    href="/assets/upload/9636932271_f178f5bc2e_o-1024x680.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/2.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2906"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">TRUFFLE FRIES</span>
                    <span className="menu_price">$6</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    Fries with the truffle mayo sauce and parmesan cheese
                  </div>
                  {/* <div className="menu_highlight">Recommended</div> */}
                </div>
              </div>

              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated5 static">
                  <div
                    data-title="<strong>Italian Source Mushroom</strong>"
                    href="/assets/upload/4435938839_0e86fdba04_o1-1024x680.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/5.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2915"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">Omelette</span>
                    <span className="menu_price">$10</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    3 eggs, creme fraiche, toast bread and side green salad with
                    lemon oil sauce
                  </div>
                </div>
              </div>
              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated6 static">
                  <div
                    data-title="<strong>Fried Potatoes With Garlic</strong>"
                    href="/assets/upload/6235831243_ba46458d17_o-1024x683.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/6.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2918"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">Pancakes</span>
                    <span className="menu_price">$11</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    Banana, strawberry, maple syrup and nutella
                  </div>
                </div>
              </div>
              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated3 static">
                  <div
                    data-title="<strong>Grilled Salmon Sushi</strong>"
                    href="/assets/upload/3723771474_b2e8636d45_o-1024x685.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/3.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2910"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">TRUFFLE BURGER</span>
                    <span className="menu_price">$14</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    130g Black Angus beef (served medium), rocca, tomato,
                    truffle mayo and swiss cheese
                  </div>
                </div>
              </div>
              <div className="element classic3_cols">
                <div className="one_third gallery3 static filterable gallery_type animated4 static">
                  <div
                    data-title="<strong>Fried Chicken Salad</strong>"
                    href="/assets/upload/7623705344_3567630aa8_o-1024x678.jpg"
                    className="fancy-gallery"
                    onClick={handleClick}
                  >
                    <img src="/assets/upload/4.png" alt="" />
                    {/* <div className="mask">
                      <div className="mask_circle">
                        <i className="fa fa-expand"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
                <br className="clear" />
                <br />
                <div
                  id="portfolio_desc_2913"
                  className="portfolio_desc portfolio3 textleft"
                >
                  <h5 className="menu_post">
                    <span className="menu_title">CHICKEN SALAD</span>
                    <span className="menu_price">$10</span>
                  </h5>
                  <div className="post_detail menu_excerpt">
                    Grilled chicken, mixed greens, green onion, carrots,
                    peanuts, sesame seeds, crispy fried onions with ginger sauce
                  </div>
                  {/* <div className="menu_highlight">Healthy</div> */}
                </div>
              </div>
            </div>
            <Lightbox
              open={openFavorite}
              close={handleCloseClick}
              slides={[
                {
                  src: "/assets/upload/1.png",
                },
                {
                  src: "/assets/upload/2.png",
                },
                {
                  src: "/assets/upload/3.png",
                },
                {
                  src: "/assets/upload/4.png",
                },
                {
                  src: "/assets/upload/5.png",
                },
                {
                  src: "/assets/upload/6.png",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div id="page_content_wrapper" class="hasbg">
        <div class="inner">
          <div class="inner_wrapper">
            <div
              id="page_main_content "
              class="sidebar_content full_width nopadding"
              style={{ padding: "30px 0px 0" }}
            >
              <h2 className="our-menu-title">Our Menu</h2>
              <div
                id="portfolio_filter_wrapper"
                class="gallery two_cols portfolio-content section content clearfix menu"
              >
                <div class="element classic2_cols">
                  <div
                    class="one_half gallery2 static filterable gallery_type animated2"
                    data-id="post-2"
                  >
                    <div
                      onClick={() => setOpen(true)}
                      data-title="<strong>Yapa Digital Menu </strong>"
                      href="/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-0.jpg"
                      class="fancy-gallery"
                    >
                      <img
                        src="/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-0.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="element classic2_cols">
                  <div
                    class="one_half gallery2 static filterable gallery_type animated3"
                    data-id="post-3"
                  >
                    <div
                      onClick={() => setOpen(true)}
                      data-title="<strong>Yapa Digital Menu </strong>"
                      href="/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-1.jpg"
                      class="fancy-gallery"
                    >
                      <img
                        src="/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[
              {
                src: "/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-0.jpg",
              },
              {
                src: "/assets/upload/Yapa-Menu-For-Digital-Use-RGB-images-1.jpg",
              },
            ]}
          />
        </div>
        <br class="clear" />
      </div>
      <div id="our-location" className="our-location">
        <div id="page_content_wrapper" className="hasbg">
          <h2 className="about-us-title">Find Us Here</h2>
          {/* Skeleton Loader */}
          {isLoading && (
            <div id="map-skeleton" className="skeleton-loader"></div>
          )}
          {/* Google Maps iframe */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.1387018706373!2d35.51892329999999!3d33.886081499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17004d7f6685%3A0x993225546301b513!2sYAPA!5e0!3m2!1sen!2slb!4v1718003409338!5m2!1sen!2slb"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Yapa"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={handleIframeLoad}
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
